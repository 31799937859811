import axios from "lib/fetch/axios";

import getSelectedIdClinic from "lib/helpers/getSelectedIdClinic";
import { TDocument } from "lib/constants/documentTypes";
import { TPatient } from "./patients";

export type TServiceStatus =
  | "being-served"
  | "queuing"
  | "already-served"
  | "checkout"
  | "in-progress"
  | "checkout-ready"
  | "instalasi-farmasi";

export type TServiceType =
  | "rekam-medis"
  | "laboratorium"
  | "ugd"
  | "rawat-inap"
  | "oncall"
  | "radiologi";

export type TService = {
  _id: string;
  type: TServiceType;
  status: TServiceStatus;
  keluhanUtama: string;
  sistole: string;
  diastole: string;
  heartRate: string;
  respiratoryRate: string;
  oxygenSaturation: string;
  patient: TPatient;
  createdAt: string;
  nomorAntrian: string;
  metaDataSatuSehat: {
    id: string;
  };
  metaData: {
    bpjs: {
      kdProviderPeserta: string;
      noKartu: string;
      noUrutPendaftaran: string;
      tglDaftar: string;
      panggilAntreanOnline: string;
      addAntreanOnline: string;
      noKunjungan: string;
      rujukBalik: string;
      kdTkp: string;
      icareConnected?: boolean;
    };
    poli: any;
  };
};

export type TFilterList = {
  page: string;
  limit: string;
  status: TServiceStatus;
  type: TServiceType;
  dateStart: string;
  dateEnd: string;
  source?:
    | "all"
    | "bpjs-antrian-online"
    | "bpjs-pcare"
    | "general"
    | "calendar";
  term?: string;
  poli?: string;
  key?: string;
  sort?: "az" | "za";
  date?: string | { start: string | undefined; end: string | undefined };
  isInstalasiFarmasi?: Boolean;
  isAsuhanKeperawatan?: Boolean;
  hasSep?: Boolean;
  satuSehatQuery?: "success" | "failed" | "all";
};

export type TFilterListReadyPaid = {
  page: string;
  limit: string;
  dateStart: string;
  dateEnd: string;
  term?: string;
  key?: string;
  date?: string | { start: string | undefined; end: string | undefined };
};

function getStates() {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/services/states`,
  });
}

function getCheckingStatusByIdService(idService: string) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/services/${idService}/checking-status`,
  });
}

function list(params: TFilterList) {
  params = {
    ...params,
    date: { start: params.dateStart, end: params.dateEnd },
  };
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL2
    }/v1/clinics/${getSelectedIdClinic()}/services`,
    params,
  });
}

function listReadyToBePaid(params: TFilterListReadyPaid) {
  params = {
    ...params,
    date: { start: params.dateStart, end: params.dateEnd },
  };
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/services/ready-to-be-paid`,
    params,
  });
}

function details(id: string) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/services/${id}`,
  });
}

function historiesByIdService(id: string) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/services/${id}/histories`,
  });
}

function getServiceByNomorSep(noSep: string) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/services/sep`,
    params: {
      noSep,
    },
  });
}

function getServiceByNomorRujukanKeluar(noRujukan: string) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/services/rujukan-keluar`,
    params: {
      noRujukan,
    },
  });
}

function createQueue(data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/services`,
    data,
  });
}

function update(data: any, step: string = "update") {
  return axios({
    method: "put",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/services/${
      data.idService
    }/${step}`,
    data,
  });
}

function cancel(id: string) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_AUTH_URL}/api/v1/services/${id}`,
  });
}

function viewLetterSuratSehat(id: string) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AUTH_URL}/api/v1/certificate-of-healths/${id}`,
  });
}

function createLetterSuratSehat(data: any) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_AUTH_URL}/api/v1/certificate-of-healths`,
    data,
  });
}

function viewLetterSuratSakit(id: string) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AUTH_URL}/api/v1/sick-letters/${id}`,
  });
}

function createLetterSuratSakit(data: any) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_AUTH_URL}/api/v1/sick-letters`,
    data,
  });
}

function createInformConsent(data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/documents/inform-consent`,
    data,
  });
}

function createInformConsentRujukan(data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/documents/inform-consent-rujukan`,
    data,
  });
}

function createPemantauanAnestesiLokal(data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/documents/pemantauanAnestesiLokal`,
    data,
  });
}

function createAssesmentPraOperasi(data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/documents/createAssesmentPraOperasi`,
    data,
  });
}

function createSuratRujukan(data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/documents/surat-rujukan`,
    data,
  });
}

function createSuratRujukanBPJS(data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/documents/surat-rujukan-bpjs`,
    data,
  });
}

function createSuratKesimpulanMCU(data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/documents/surat-kesimpulan-mcu`,
    data,
  });
}

function createSuratKeteranganLayakTerbang(data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/documents/surat-keterangan-layak-terbang`,
    data,
  });
}

function createDefaultDocument(type: TDocument, data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/documents/default-document`,
    params: {
      type,
    },
    data,
  });
}

function getAllDocs(id: string) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/services/${id}/documents`,
  });
}

const exported = {
  getStates,
  createQueue,
  list,
  listReadyToBePaid,
  details,
  historiesByIdService,
  update,
  viewLetterSuratSehat,
  createLetterSuratSehat,
  viewLetterSuratSakit,
  createLetterSuratSakit,
  createInformConsent,
  createSuratRujukan,
  createSuratRujukanBPJS,
  createSuratKesimpulanMCU,
  createSuratKeteranganLayakTerbang,
  createPemantauanAnestesiLokal,
  createAssesmentPraOperasi,
  createInformConsentRujukan,
  getCheckingStatusByIdService,
  createDefaultDocument,

  getAllDocs,
  cancel,

  // vclaim
  getServiceByNomorSep,
  getServiceByNomorRujukanKeluar,
};

export default exported;
